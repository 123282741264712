var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchBar',{attrs:{"context":_vm.context,"search-fields":_vm.searchFields,"filterItems":_vm.filterItems}}),_c('v-card',{staticClass:"main-card",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-1 px-0"},[_c('v-data-table',{staticClass:"main-table elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.tableItemsCount,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray },"item-key":"entity.id"},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('entity-status',{attrs:{"report":item.report}})]}},{key:"item.step",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("labels." + (item.stateObj ? item.stateObj.display : ''))))+" ")]}},{key:"item.firm_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.firm_code)+" ")]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.provider.toUpperCase())+" ")]}},{key:"item.clients",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entity.client_names ? item.entity.client_names.join(', ') : '')+" ")]}},{key:"item.has_home_workflow",fn:function(ref){
var item = ref.item;
return [(item.entity.has_home_workflow)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))]):_vm._e()]}},{key:"item.has_life_workflow",fn:function(ref){
var item = ref.item;
return [(item.entity.has_life_workflow)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))]):_vm._e()]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.entity.created_on))+" ")]}},{key:"item.updated_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.entity.updated_on))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","to":_vm.getBoardItemRoute(item.entity)}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.view))])],1)]}}],null,true)})],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }