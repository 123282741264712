<template>
  <div>
    <SearchBar :context="context" :search-fields="searchFields" :filterItems="filterItems" />
    <v-card flat class="main-card">
      <v-card-text class="py-1 px-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :server-items-length="tableItemsCount"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          item-key="entity.id"
          class="main-table elevation-0"
        >
          <template v-slot:[`item.report`]="{ item }">
            <entity-status :report="item.report" />
          </template>
          <template v-slot:[`item.step`]="{ item }">
            {{ $t(`labels.${item.stateObj ? item.stateObj.display : ''}`) }}
          </template>
          <template v-slot:[`item.firm_code`]="{ item }">
            {{ item.entity.firm_code }}
          </template>
          <template v-slot:[`item.provider`]="{ item }">
            {{ item.entity.provider.toUpperCase() }}
          </template>

          <template v-slot:[`item.clients`]="{ item }">
            {{ item.entity.client_names ? item.entity.client_names.join(', ') : '' }}
          </template>

          <template v-slot:[`item.has_home_workflow`]="{ item }">
            <v-icon small color="success" v-if="item.entity.has_home_workflow">{{
              $vuetify.icons.values.check
            }}</v-icon>
          </template>

          <template v-slot:[`item.has_life_workflow`]="{ item }">
            <v-icon small color="success" v-if="item.entity.has_life_workflow">{{
              $vuetify.icons.values.check
            }}</v-icon>
          </template>

          <template v-slot:[`item.created_on`]="{ item }">
            {{ transformDate(item.entity.created_on) }}
          </template>

          <template v-slot:[`item.updated_on`]="{ item }">
            {{ transformDate(item.entity.updated_on) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small icon :to="getBoardItemRoute(item.entity)">
              <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDT } from '@argon/app/helpers'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { EntityStatus, SearchBar } from '@argon/evo/entities/components'
import { EntityDatatableMixin } from '@argon/evo/entities/mixins'

export default {
  name: 'LeadTableView',
  components: {
    SearchBar,
    EntityStatus
  },
  mixins: [EntityDatatableMixin],
  data: function () {
    return {
      boardCardLink: 'leadPage',
      headers: [
        { text: '', value: 'report', sortable: false, width: 20 },
        { text: this.$t('labels.step'), value: 'step', sortable: false, width: 110 },
        { text: this.$t('firm'), value: 'firm_code', width: 80 },
        { text: this.$t('labels.provider'), value: 'provider', width: 80 },
        { text: this.$t('has_life'), value: 'has_life_workflow', sortable: false, width: 15 },
        { text: this.$t('has_home'), value: 'has_home_workflow', sortable: false, width: 15 },
        { text: this.$t('labels.provider_lead_id'), value: 'entity.provider_lead_id', sortable: false, width: 160 },
        { text: this.$t('labels.source_lead_id'), value: 'entity.source_lead_id', sortable: false, width: 150 },
        { text: this.$t('labels.createdOn'), value: 'created_on', width: 115 },
        { text: this.$t('labels.updatedOn'), value: 'updated_on', width: 115 },
        { text: '', value: 'actions', sortable: false, align: 'right', width: 30 }
      ],
      searchFields: [
        { label: 'display', type: 'text' },
        {
          label: 'step',
          type: 'list',
          values: ['pending', 'broker_created', 'clients_created', 'deals_created', 'completed']
        },
        { label: 'provider_lead_id', type: 'text' },
        { label: 'source_lead_id', type: 'text' },
        { label: 'firm_code', type: 'text' },
        { label: 'provider', type: 'text' }
      ],
      counts: null,
      include: 'firm',
      sortBy: 'created_on',
      sortDesc: true
    }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    }
  }
}
</script>

<i18n>
{
  "en": {
    "firm": "Firm",
    "has_home": "H",
    "has_life": "L"
  },
  "fr": {
    "firm": "Firme",
    "has_home": "H",
    "has_life": "V"
  }
}
</i18n>
